html[data-theme='CARTER_JONAS'] {
  --color-ppa-sidebarBackground: #2a004a;
  --color-ppa-sidebarButtonBackground: #5f4077;
  --color-ppa-sidebarButtonText: #9586ad;
  --color-ppa-sidebarButtonTextActive: #ffffff;
  --color-ppa-sidebarButtonSubmenuTextActive: #ffffff;
  --color-ppa-sidebarButtonIcon: #9586ad;
  --color-ppa-sidebarButtonIconActive: #ffffff;

  --color-ppa-secondaryButtonBackground: #ffffff;
  --color-ppa-quaternaryButtonBackground: #eeeeee;

  --color-ppa-sidebarProfileName: #c4c4e1;
  --color-ppa-sidebarProfileEmail: #9586ad;
  --color-ppa-sidebarProfileBackground: #ffffff;
  --color-ppa-sidebarProfileIcon: #2a004a;

  --color-ppa-primary: #2a004a;
  --color-ppa-primaryFade: #626b6b1a;
  --color-ppa-primarySelected: #8d3dca;
  --color-ppa-primaryActive: #2a004a0d;

  --color-ppa-secondary: #2a004a;
  --color-ppa-secondaryFade: #626b6b1a;
  --color-ppa-secondarySelected: #8d3dca;
  --color-ppa-secondaryActive: #9586ad33;

  --color-ppa-title: #2a004a;
  --color-ppa-subtitle: #555566;
  --color-ppa-formStar: #5f4077;
  --color-ppa-formText: #2a004acc;
  --color-ppa-filterBorder: #e6e8ec;
  --color-ppa-filterBorderActive: #3385ff;

  --color-ppa-tableTitleText: #4b464f;
  --color-ppa-tableTitleBackground: #2a004a0d;
  --color-ppa-tableRowHover: #9586ad33;
  --color-ppa-tableRowText: #6b7280;
  --color-ppa-tableRowHighlight: #356fd7;
}
