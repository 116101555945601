@tailwind base;
@tailwind components;
@tailwind utilities;

/*
TailwindCSS breakpoints:
  sm: 640px
  md: 768px
  lg: 1024px
  xl: 1280px
*/

@layer base {
  html {
    --color-ppa-grayText: #555566;
    --color-ppa-grayTextTitle: #4b464f;
    --color-ppa-grayBackground: #f6f8fa;
    --color-ppa-grayBorder: #e3e8f0;
    --color-ppa-lightGrayBorder: #e6e7ea;
    --color-ppa-placeholder: #9ca3af;
    --color-ppa-brokerFeeArrow: #E6E9EE

    --color-ppa-warning: #0090ff;
    --color-ppa-orangeTextPill: #dc7e4b;
    --color-ppa-orangeBgPill: #ffe9dd;

    --color-ppa-blueFocus: #356fd7;
    --color-ppa-blueTextPill: #7782c6;
    --color-ppa-blueBgPill: #e9f1ff;

    --color-ppa-grayTextPill: #858585;
    --color-ppa-grayBgPill: #ededed;

    --color-ppa-yellowWarning: #ec971c;
    --color-ppa-yellowBgPill: #fff7da;
    --color-ppa-yellowTextPill: #be914e;
    --color-ppa-yellowPriceChange: #ffd500;

    --color-ppa-redError: #f02222;
    --color-ppa-redTextPill: #ab6a6a;
    --color-ppa-redBgPill: #ece0e0;

    --color-ppa-greenSuccess: #83bb35;
    --color-ppa-greenPriceChange: #22c55e;
    --color-ppa-greenTextPill: #5c9067;
    --color-ppa-greenBgPill: #ecfcf0;

    --color-ppa-statusNotAccepted: #f4f4f4;
    --color-ppa-statusExecuted: #83bb351a;

    --color-ppa-blackShadow: rgba(0, 0, 0, 0.2);
    --color-ppa-lightBlackShadow: rgba(0, 0, 0, 0.12);
  }

  @import './themes/ppaya.css';
  @import './themes/carterJonas.css';
}

html,
body {
  overflow: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

input,
button,
select,
textarea,
a {
  outline: none !important;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
  outline: none !important;
}

a {
  transition: filter 0.25s ease-in-out;
}

a:focus,
a:focus-within,
a:focus-visible {
  filter: brightness(75%);
}

.fade-in {
  animation: fade-in forwards 0.25s ease-in-out 1;
}

/**
@FIXME: In order to use this animation, we need to refactor the drawers and modals because position: relative and transform don't work together
@see: thttps://stackoverflow.com/questions/15194313/transform3d-not-working-with-position-fixed-children
#root > section > main > * {
  animation: fade-in forwards .25s ease-in-out 1;
}
**/

/**
  * The following code it's a workaround for this current bug
  * @SEE https://github.com/JedWatson/react-select/issues/5205
  * @FIXME The git issue has been updated. Check it out and update the code below
  */
.react-select-container > div:nth-of-type(1) > div:nth-of-type(1) > div {
  width: unset !important;
}

.scroll-shadow-horizontal {
  top: 0;
  overflow: auto;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, #e5e5e5, rgba(255, 0, 0, 0)),
    linear-gradient(to left, #e5e5e5, rgba(255, 0, 0, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.w-md-editor-toolbar {
  background-color: transparent !important;
  border-bottom: 1px solid #ccd3dc !important;
  box-shadow: none !important;
}

.w-md-editor-toolbar svg {
  color: #667a95;
}

.w-md-editor-preview ul,
.w-md-editor-preview ol {
  padding-left: 10px;
}

.w-md-editor-preview ul li {
  list-style-type: disc;
  margin-left: 10px;
}

.w-md-editor-preview ol li {
  list-style-type: decimal;
  margin-left: 10px;
}
